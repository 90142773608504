import { useEffect } from 'react'
import { PageNotFoundError } from 'next/dist/shared/lib/utils'

import { FlexibleHeading as Heading } from '@big-red-group/storefront-common-ui'
import { ContentWrapper, HeadingWrapper, StylizedText, Wrapper } from '@/components/NotFoundPage.styles'
import BackgroundImage from '@/components/BackgroundImage'
import Container from '@/components/Container'
import { useRouter } from 'next/router'
import { useAnalyticsError } from '@/hooks/AnalyticsErrorHook'
import { constructFullUrl } from '@/helpers/ClientSideRenderHelper'
import Button from '@/components/Button'

const NotFoundPage: React.FC = () => {
  const router = useRouter()
  const errorOccurred = useAnalyticsError()

  useEffect(() => {
    const url = constructFullUrl(router.asPath)
    errorOccurred(new PageNotFoundError(`Error occurred when fetching page from URL ${url}`), true)
  })

  return (
    <BackgroundImage imageUrl="/images/404-image.webp">
      <Wrapper>
        <Container>
          <ContentWrapper>
            <HeadingWrapper>
              <Heading as="h1" size="XL" color="text-inverse">
                Oops! We&rsquo;ve <StylizedText>Lost</StylizedText> this page!
              </Heading>
              <Heading size="S" color="text-inverse" weight="regular">
                Sorry the page you are looking for doesn&rsquo;t exist or has been moved.
              </Heading>
            </HeadingWrapper>
            <Container size="fullWidth">
              <Button size="L" onClick={() => router.push(`${router.query?.lang || 'en'}`)}>
                Back to homepage
              </Button>
            </Container>
          </ContentWrapper>
        </Container>
      </Wrapper>
    </BackgroundImage>
  )
}

export default NotFoundPage
