import styled from 'styled-components'
import { BREAKPOINT_SMALL } from '@/constant/ui'

export const Wrapper = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%);
`

export const ContentWrapper = styled.div`
  max-width: 550px;
  padding: 200px 72px;
  gap: 48px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    padding: 190px 0;
    gap: 24px;
  }
`

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-wrap: balance;
`

export const FancyText = styled.span`
  font-family: var(--font-fancy);
  color: var(--brand-primary);
  font-size: 120px;
  line-height: 1;

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    font-size: 80px;
  }
`

export const StylizedText = styled.span`
  background-color: var(--brand-accent);
  color: var(--text-primary);
  padding: 10px 4px 0px 4px;
`
